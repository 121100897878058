import 'react-toastify/dist/ReactToastify.min.css';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Worker } from '@react-pdf-viewer/core';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWSClient, splitLink, TRPCClientError, wsLink } from '@trpc/client';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { posthog } from 'posthog-js';
import { PropsWithChildren, useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppRouter } from '@/common/types';
import { AUTH0_AUDIENCE, ENVIRONMENT, MARVERI_API_WS_BASE_URL } from '@/constants';
import { setToken, token, trpcBatchLink, trpcReact } from '@/utils/trpc';

interface ColoredNavLinkProps {
  to: string;
}

export const ColoredNavLink = (props: PropsWithChildren<ColoredNavLinkProps>) => {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        isActive ? 'text-marveri-gold' : 'text-marveri-silver hover:text-marveri-light-gold'
      }
    >
      {props.children}
    </NavLink>
  );
};

export const Root = () => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [requiresEmailVerify, setRequiresEmailVerify] = useState(false);

  // sets access token so we can pass it to TRPC Authorization header
  useEffect(() => {
    const setAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: '*:client-matter',
          },
        });
        setToken(accessToken);
      } catch (error) {
        await logout({ logoutParams: { returnTo: window.location.origin } });
      }
    };
    setAccessToken();
  }, [getAccessTokenSilently, logout]);

  const clientLinks = {
    links: [
      splitLink({
        // If there is a subscription request, use the websocket link, otherwise use the http batch link
        condition(op) {
          return op.type === 'subscription';
        },
        true: wsLink<AppRouter>({
          client: createWSClient({
            url: MARVERI_API_WS_BASE_URL,
            retryDelayMs: () => 2000, //Wait 2 second before retrying for
            //websocket reconnection
          }),
        }),
        false: trpcBatchLink,
      }),
    ],
  };

  const trpcReactClient = trpcReact.createClient(clientLinks);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Prevent refetching on window focus since we're using subscriptions
        refetchOnWindowFocus: false,
        enabled: token !== undefined,
        retry: (failureCount: number, error: unknown) => {
          if (!(error instanceof TRPCClientError)) {
            return false;
          }

          const retryableCodes = new Set([
            'BAD_REQUEST',
            'TIMEOUT',
            'INTERNAL_SERVER_ERROR',
            'TOO_MANY_REQUESTS',
          ]);

          if (!retryableCodes.has(error.data.code)) {
            return false;
          }

          return failureCount < 3;
        },
      },
    },
    queryCache: new QueryCache({
      onError: async (error) => {
        const typedError = error as {
          message: string;
          data: { httpStatus: number };
        };
        if (typedError?.data?.httpStatus === 401) {
          if (typedError?.message.includes('verified')) {
            setRequiresEmailVerify(true);
          } else {
            await logout({ logoutParams: { returnTo: window.location.origin } });
          }
        }
      },
    }),
  });

  const Layout = () => {
    const user = trpcReact.user.getCurrentUser.useQuery().data;
    const ldClient = useLDClient();

    useEffect(() => {
      if (user && user.email && ENVIRONMENT === 'production') {
        LogRocket.identify('user', {
          name: user?.firstName + ' ' + user?.lastName,
          email: user?.email || 'Unknown',
          marveriEmployee: user.email.endsWith('@marveri.com'),
        });
      }
      if (ldClient && user && user.email) {
        ldClient.identify({
          key: user.id,
          marveriEmployee: user.email.endsWith('@marveri.com'),
          email: user.email,
          name: user?.firstName + ' ' + user?.lastName,
        });
      }
      if (user && user.email) {
        posthog.identify(user.id, {
          email: user.email,
          name: user?.firstName + ' ' + user?.lastName,
          marveriEmployee: user.email.endsWith('@marveri.com'),
        });
      }
    }, [ldClient, user]);

    return (
      <div className="flex h-screen w-screen flex-col bg-black">
        <div className="flex size-full bg-marveri-background">
          <Outlet />
          <ToastContainer className="flex flex-col items-center justify-center" />
        </div>
      </div>
    );
  };

  const VerifyEmail = () => (
    <div className="flex-col space-y-4 px-[98px] pt-[50px] text-marveri-white">
      <h2 className="text-[20px] font-bold text-[#E6E6DF]">Verify your email address.</h2>
      <p>Please click on the link in the email we just sent you to confirm your email address.</p>
      <p
        className="cursor-pointer"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Confirm verification ⟳
      </p>
      <p
        className="cursor-pointer"
        onClick={async () => {
          await logout({ logoutParams: { returnTo: window.location.origin } });
        }}
      >
        Logout →
      </p>
    </div>
  );

  const ProtectedLayout = withAuthenticationRequired(Layout);

  // We include the trpcReact client in the trpcReact here rather than higher in the tree so that the websocket doesn't attempt to connect until after authentication has been established
  return (
    <trpcReact.Provider client={trpcReactClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Worker workerUrl={`/pdf.worker.min.js`}>
          {requiresEmailVerify ? (
            <div className="flex h-screen w-screen flex-col items-center justify-center bg-black bg-[url('./assets/images/login-background-2.png')] bg-cover bg-no-repeat">
              <div className="relative flex h-3/5 min-h-[450px] w-[650px] flex-col items-center rounded-[5px] border-2 border-light-border bg-[#1b1b1b]">
                <VerifyEmail />
              </div>
            </div>
          ) : (
            <ProtectedLayout />
          )}
        </Worker>
      </QueryClientProvider>
    </trpcReact.Provider>
  );
};
